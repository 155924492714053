.comments-container{
    margin-top:20px; 
    padding-top:20px;
    border-top:solid 2px black;
 }
 .comment{
    margin:10px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    border:solid 1px grey;
 }
 .comment-author{
  margin-right:20px;
 }