.category-articles{
    min-height:100vh;
    display:grid;
    grid-template-columns: repeat(3,1fr);
}

@media(max-width:768px){
    .category-articles{
        grid-template-columns: repeat(2,1fr);
    }
     
}

@media(max-width:468px){
    .category-articles{
        grid-template-columns: 1fr;
    }
     
}