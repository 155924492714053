.selected-articles {
    width:90%;
    margin:50px auto;
}

.selected-article-item {
    width: fit-content;
    height: 200px;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    color:var(--white);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 0 10px 10px;
    margin: 10px;
    cursor: pointer;
}

.selected-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    box-shadow: 5px 5px 5px black;
}

.selected-info {
    width: 40vh;
    height: content;
    background-color: var(--transparent-bg);
    padding:5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
}

.selected-info p {
    flex-wrap: wrap;
    padding: 5px;
    font-style: italic;
}


@media(max-width:900px){

    .selected-articles {
        margin: auto;
    }

    .selected-article-item {
        height: max-content;
    }

    .selected-image {
        width: 150px;
    }

    .selected-info {
        width: fit-content;
        height: fit-content;
        min-height: content;
    }
}