.page-layout {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas:
        'name name .'
        '. sections sections'
        '. sections sections';
    grid-gap: 5px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 3fr 2fr;
}

.name {
    grid-area: name;
    font-size: 100px;
    padding: 10px;
    justify-self: center;
    align-self: center;
}

.sections-container {
    grid-area: sections;
}

.tabs {
    display: flex;
    font-size: 50px;
    margin-left: 50px;
    padding-left: 50px;
    color: white;
    text-shadow: 0 0 4px black, 0 0 4px black, 0 0 4px black;
}

.portfolio {
    background-image: linear-gradient(to right, var(--darkgreen), var(--lightgreen));
}

.portfolio:hover {
    background-image: linear-gradient(to right, var(--darkgreen), var(--darkgreen));
}

.about {
    background-image: linear-gradient(to right, var(--darkblue), var(--lightblue));
}

.about:hover {
    background-image: linear-gradient(to right, var(--darkblue), var(--darkblue));
}

.contact {
    background-image: linear-gradient(to right, var(--darkpurple), var(--lightpurple));
}

.contact:hover {
    background-image: linear-gradient(to right, var(--darkpurple), var(--darkpurple));
}


@media screen and (max-width: 600px) {
    .landing-page {
        grid-template-areas: "name" "sections";
        grid-template-rows: 1fr 2fr;
        grid-template-columns: 1fr;
    }
}
