.add-article-container{
    min-height:100vh;
}
.add-article-form{
    width:80%;
    margin:20vh auto;
    padding:20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:16px;
    box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
}
.input-group{
    display: flex;
    flex-direction: column;
    width:80%;
    margin-top:20px;
}
.input-group input,
.input-group textarea,
.input-group select{
  margin-top:10px;
  padding:10px;
  border-radius:8px;
  border:solid 1px grey;
}
.input-group textarea{
    min-height:150px;
    resize: vertical;
}

.input-group input::file-selector-button{
 border:solid 1px grey;
 padding:8px 16px;
 border-radius:8px;
 background-color: white;
 cursor:pointer;
}


.add-article-form button{
    background-color: var(--button-bg);
    border:none;
    color:white;
    padding:8px 16px;
    border-radius:8px;
    margin-top:20px;
    width:80%;
    height:50px;
    cursor:pointer;
    font-size:24px;
}