.a-page {
    display: grid;
    grid-template-areas:
        'sidebar title .'
        'sidebar projects projects'
        'sidebar projects projects';
    grid-gap: 5px;
    grid-template-rows: 1fr 3fr 3fr;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.a-title {
    font-size: 50px;
    grid-area: title;
}

.about-section {
    grid-area: projects;
}