.sidebar-container {
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    grid-area: sidebar;
    background-color: var(--sidebarblue);
    width: 50px;
    border-top: 1px solid black;
    position: sticky;
    left: 0px;
    z-index: 2;
}

@media only screen and (max-width:700px) {
    .sidebar-container {
        visibility: hidden;
    }
}

.sidebar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 45px;
    height: 50px;
    transition: width .25s;
}

.hover-false {
    width: 50px;
}

.hover-true {
    width: 100px;
    height: 56px;
    font-size: 20px;
}

.p-side {
    background-color: var(--darkgreen);
}

.a-side {
    background-color: var(--darkblue);
}

.c-side {
    background-color: var(--darkpurple);
}