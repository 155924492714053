.fb-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color:  rgba(70, 163, 250, 0.568);
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    transition: all .5s;
    color: white;
    z-index: 2;
}

.fb-footer-container:hover {
    background-color: rgb(0, 119, 255);
    font-size: 18px;
}