.individual-project {
    display: grid;
    grid-template-areas:
    "proj-tab proj-title"
    "proj-img proj-img"
    "proj-desc proj-desc"
    "proj-link proj-link" ;
    grid-template-columns: 0px 100%;
    grid-template-rows: 1fr 2fr 2fr 1fr;
    padding: 5px;
    width: 300px;
    min-width: 300px;
    justify-content: center;
    margin-right: 10px;
}

.bg-color-tab {
    grid-area: proj-tab;
    height: 20px;
    width:60px;
    border-radius: 10px 10px 0 0;
    position: relative;
    bottom: 25px;
    right: 10px;
}

.proj-title {
    grid-area: proj-title;
    text-align: center;
    font-size: 30px;
}

.proj-img {
    grid-area: proj-img;
}

.proj-desc {
    grid-area: proj-desc;
    margin: 0 5px 0 5px;
    overflow-y: auto;
    /* Firefox Only */
    scrollbar-width: thin;
    scrollbar-color: var(--lightmodetext);
}

.proj-link {
    grid-area: proj-link;
    display: flex;
    border: 2px solid var(--dark-bg);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.indv-placeholder-img {
    text-align: center;
    font-size: 40px;
    background-color: var(--darkblue);
    color: var(--darkmodetext);
    border-radius: 5px;
}

/* Scrollbar Styling for Browsers Other Than Firefox */

.proj-desc::-webkit-scrollbar {
    width: 5px;
}

.proj-desc::-webkit-scrollbar-thumb {
    background: var(--lightmodetext);
    border-radius: 5px;
}