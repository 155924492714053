.details-container{
    width:80%;
    margin:20px auto;
    min-height:100vh;
}

.details-container h1{
    border-bottom:solid 2px black;
    padding-bottom:20px;
    margin-bottom:20px;
}
.details-container h2{
    padding-bottom:10px;
    margin-bottom:10px;
    font-weight:300;
}
.details-info-container{
    display: flex;
    align-items: center;
}
.published{
    margin-left:10px;
}
.article-span{
    font-weight: 600;
}
.article-description{
    margin-top:20px;
}
.details-image{
    width:100%;
    height:40vh;
    object-fit: cover;
    margin-top:10px;
    /* object-position:center; */
}