.p-page {
    display: grid;
    grid-template-areas:
        'sidebar title .'
        'sidebar projects projects'
        'sidebar projects projects';
    grid-gap: 5px;
    grid-template-rows: 1fr 3fr 3fr;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    height: 100%;
    width: 100%;
    overflow-x: auto;
}

.p-title {
    font-size: 50px;
    grid-area: title;
    position: sticky;
    left: 60px;
}

.projects-section {
    grid-area: projects;
    display: flex;
    width: 90%;
    height: 90%;
}

@media only screen and (max-width:700px) {
    .p-title {
        left: 20px;
    }
}