.c-page {
    display: grid;
    grid-template-areas:
        'sidebar title .'
        'sidebar projects projects'
        'sidebar projects projects';
    grid-gap: 5px;
    grid-template-rows: 1fr 3fr 3fr;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    height: 100%;
    width: 100%;
}

.c-title {
    font-size: 50px;
    grid-area: title;
}

.contact-section {
    grid-area: projects;
}

.contact-form{
    width:80%;
    min-width: 500px;
    margin: auto;
    padding:20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:16px;
    box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 17px 0px rgba(0,0,0,0.75);
}

.contact-input{
    display: flex;
    flex-direction: column;
    width:80%;
    margin-top:20px;
}

.contact-input input,
.contact-input textarea,{
  margin-top:10px;
  padding:10px;
  border-radius:8px;
  border:solid 1px grey;
}
.contact-input textarea{
    min-height:150px;
    resize: vertical;
}

.c-count {
    margin-top: 0px;
    text-align: right;
}

.c-submit {
    border: none;
    color: inherit;
    background-color: #09a7e5;
    font-size: 30px;
    border-radius: 10px;
    margin-top: 10px;
}

.more-contact {
    text-align: center;
    min-width: 500px;
}

@media screen and (max-width:700px) {

    .contact-form {
        width:90%;
        min-width: 300px;
        margin: auto;
        padding:10px 0;
    }

    .contact-input {
        display: flex;
        flex-direction: column;
        width:80%;
        margin-top:20px;
    }
    
    .contact-input input,
    .contact-input textarea {
      margin-top:10px;
      padding:10px;
      border-radius:8px;
      border:solid 1px grey;
    }
    .contact-input textarea {
        min-height:50px;
        resize: none;
    }
    
    .c-count {
        margin-top: 0px;
        text-align: right;
    }

    .more-contact {
        min-width: 300px;
    }
}